import { makeStyles } from 'tss-react/mui';

const usePayoutCardStyle = makeStyles()(() => ({
    infoTooltip: {
        height: '20px',
    },
    ibanInput: {
        width: '300px',
    },
}));

export default usePayoutCardStyle;
