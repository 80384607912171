import { IMaskInput } from 'react-imask';
import { forwardRef } from 'react';
import { IbanMaskT } from './IbanMask.types';

const IbanMask = forwardRef<HTMLElement, IbanMaskT>(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            inputRef={ref}
            // eslint-disable-next-line
            onAccept={(value: any) => onChange({ target: { value } })}
            overwrite
        />
    );
});

export default IbanMask;
