import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { Trans, useTranslation } from 'react-i18next';
import CarbonBankApiService from '@services/carbonBankApiService/carbonBankApiService';
import CardBasic from '@components/CardBasic/CardBasic';
import ButtonIconAction from '@components/ButtonIconAction/ButtonIconAction';
import FullPageLayout from '@layouts/FullPageLayout/FullPageLayout';
import { FarmSeasonCertificateT, FarmSeasonPurchaseT } from '@services/carbonBankApiService/carbonBankApiServiceTypes';
import { Box, Link, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { PRODUCT_PLAN_ID } from '@constants/productPlan';

import PrintableTable from './components/PrintableTable/PrintableTable';
import CertificateTable from './components/CertificateTable/CertificateTable';
import useHomePageLoading from '@hooks/useHomePageLoading';
import useCarbonbBankStyle from './CarbonBank.style';
import FarmSeasonProductPlanApiService from '@services/productPlanApiService/productPlanApiService';
import useInitialFetch from '@hooks/useInitialFetch';
import PurchaseTable from './components/PurchaseTable/PurchaseTable';

const CarbonBank = (): JSX.Element => {
    const { t } = useTranslation();
    const { addPendingState } = useHomePageLoading();
    const firstSeasonId = useSelector((state: HomePageStateT) => {
        const filteredSeasons = state.farmSeason.farmSeasonList.filter((farmSeason) => farmSeason.has_certificates);
        if (filteredSeasons.length === 0) return null;
        return filteredSeasons.reduce((previous, current) => {
            return current.season_id < previous.season_id ? current : previous;
        }).season_id;
    });

    const firstHarvestYear = useSelector(
        (state: HomePageStateT) => state.static.seasons?.find((season) => season.id === firstSeasonId)?.harvest_year,
    );
    const farm = useSelector((state: HomePageStateT) => state.farm.currentFarm);
    const farmId = farm?.id;
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason?.id);
    const language_id = useSelector((state: HomePageStateT) => state.auth.user.language_id);

    const [print, setPrint] = useState<() => void>();
    const [certificates, setCertificates] = useState<FarmSeasonCertificateT[]>([]);
    const [purchases, setPurchases] = useState<FarmSeasonPurchaseT[]>([]);

    const { classes } = useCarbonbBankStyle();

    useEffect(() => {
        if (farm) {
            const request = CarbonBankApiService.getPayments(farm.id).then((r) => setPurchases(r.data));
            addPendingState({ pending: request, slug: 'CarbonBank-getPayments' });
        }
    }, [farm]);

    useEffect(() => {
        if (farm) {
            const request = CarbonBankApiService.getCertificates(farm.id).then((r) => setCertificates(r.data));
            addPendingState({ pending: request, slug: 'CarbonBank-getCertificates' });
        }
    }, [farm]);

    const { data: previousProductPlan, isLoading: isPreviousProductPlanLoading } = useInitialFetch(
        () => FarmSeasonProductPlanApiService.getPreviousProductPlan({ farmId, farmSeasonId }),
        null,
        [farmSeasonId],
    );

    useEffect(() => {
        addPendingState({ pending: isPreviousProductPlanLoading, slug: 'CarbonBank-getPreviousProductPlan' });
    }, [previousProductPlan]);

    return (
        <>
            <FullPageLayout
                actionZone={<ButtonIconAction iconType="pdf" size="medium" onClick={print} />}
                title={t('pages.carbon-bank.certificates.title')}
            >
                <div className={classes.textInputContainer}>
                    <Typography color="secondary" variant="body1">
                        <Trans
                            i18nKey="pages.carbon-bank.certificates.payment-banner-description"
                            values={{
                                seasonProductPlan:
                                    previousProductPlan?.id === PRODUCT_PLAN_ID.STANDARD
                                        ? t('pages.settings.billing.plan.plan-name.standard')
                                        : t('pages.settings.billing.plan.plan-name.basic'),
                                productPlanCertificatePercentage:
                                    previousProductPlan?.id === PRODUCT_PLAN_ID.STANDARD ? '100' : '70',
                            }}
                        />
                    </Typography>
                </div>
                {previousProductPlan ? (
                    <CardBasic>
                        <CertificateTable
                            farmSeasonCertificates={certificates ?? []}
                            firstHarvestYear={firstHarvestYear ?? NaN}
                            previousProductPlanId={previousProductPlan.id}
                        />
                    </CardBasic>
                ) : null}

                {/* ------------------------------- Intercom Link ------------------------------ */}
                <Box className={classes.iconAndInfoRow}>
                    <LaunchIcon />
                    <Link
                        sx={{ ml: 1 }}
                        href={
                            language_id == 3
                                ? 'https://intercom.help/soil-capital-19dac59588e7/fr/articles/9490472-comment-fonctionne-la-reserve-de-certificats'
                                : 'https://intercom.help/soil-capital-19dac59588e7/en/articles/9490472-how-the-certificate-buffer-works'
                        }
                        target="_blank"
                    >
                        <Typography variant="body2" color="primary">
                            {t('pages.carbon-bank.certificates.intercom-link')}
                        </Typography>
                    </Link>
                </Box>

                {/* ------------------------ Purchases transactions table ----------------------- */}
                {purchases.length > 0 && (
                    <>
                        <div className={classes.textInputContainer}>
                            <Typography color="secondary" variant="h4">
                                {t('pages.carbon-bank.purchase-table.title')}
                            </Typography>
                        </div>
                        <CardBasic>
                            <PurchaseTable farmSeasonPurchase={purchases} />
                        </CardBasic>
                    </>
                )}
            </FullPageLayout>

            {previousProductPlan ? (
                <PrintableTable
                    CertificateTableProps={{
                        farmSeasonCertificates: certificates ?? [],
                        firstHarvestYear: firstHarvestYear ?? NaN,
                        previousProductPlanId: previousProductPlan.id,
                    }}
                    setPrint={setPrint}
                />
            ) : null}
        </>
    );
};

export default CarbonBank;
